import { UPDATE_STATISTIC, UPDATE_STATISTIC_MANAGER } from "../constants/userapp";

let initalState = {
  statistics: {
    none: '',
    approved: '',
    disapproved: ''
  },
  statistics_manager: {
    none: '',
    approved: '',
    disapproved: ''
  }
};

const userAppReducer = (state = initalState, action) => {
  switch (action.type) {
    case UPDATE_STATISTIC: 
      {
        const updateState = { ...state };
        const result = action.payload;
        updateState.statistics.none = result.filter(data => data.application_approve === '').length;
        updateState.statistics.disapproved = result.filter(data => String(data.application_approve) === '0').length;
        updateState.statistics.approved = result.filter(data => String(data.application_approve) === '1').length;

        return updateState;
      }
    case UPDATE_STATISTIC_MANAGER: 
      {
        const updateState = { ...state };
        const result = action.payload;
        updateState.statistics_manager.none = result.filter(data => data.application_approve === '').length;
        updateState.statistics_manager.disapproved = result.filter(data => String(data.application_approve) === '0').length;
        updateState.statistics_manager.approved = result.filter(data => String(data.application_approve) === '1').length;

        return updateState;
      }
    default:
      return state;
  }
};
export default userAppReducer;